<template>
	<div ref="termly">
		<div
			name="termly-embed"
			data-id="465032bb-f46b-4de2-bd95-ee7766073c0f"
			data-type="iframe"
		></div>
	</div>
</template>

<script>
export default {
	name: 'TermsOfServicePage',
	mounted() {
		const script = document.createElement('script');
		script.src = 'https://app.termly.io/embed-policy.min.js';
		script.id = 'termly-jssdk';
		this.$refs.termly.appendChild(script);

		if (this.$route.query.jumpto) {
			try {
				const jump = this.$el.getElementsByClassName(
					this.$route.query.jumpto
				)[0];
				jump.scrollIntoView();
			} catch (err) {
				// empty
			}
		}
	},
};
</script>
<style scoped>
.tos-page {
	min-width: 100%;
	min-height: 100%;
}
</style>
